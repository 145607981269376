<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <div class="sidebar-header">
        <a [routerLink]="['/']" class="app-logo">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="173px" height="80px" viewBox="0 0 173 80" enable-background="new 0 0 173 80" xml:space="preserve">  <image id="image0" width="173" height="80" x="0" y="0"
                href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAK0AAABQCAYAAABro/WEAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
            AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
            CXBIWXMAAC4jAAAuIwF4pT92AAAeHklEQVR42u2deXxU1dnHv+fO3DuTkHWGnSSAG4KKIiCEQBFr
            ra1r3UVbxSrurVLXuiCudalVSrWKVdT6qtVXLdbXBRX1xSBFfRVRK4qYTEAQMiQsWebO3PP+ce6Q
            yWTuzJ0sJEp+n898kpmz3Oec+5znPOc5z3mOoBe7DKq8xZM1of1CIvcQgq1YzCuNht/rbrqyhdbd
            BPRi58GDtp9EHqjhmQ08jybu/UYP/rK76coWvUy7C6EkWnu/JnhDCuvw0kj4v6Ne7RgPjK/SA2d2
            N2296IUjQkbfPauN4KPx76sIFFTrgcXdTVc26JW0uxys2QLpiX/bi/AWBJ9X68HXqnz9du9u6txA
            dDcBPRQB4Jg06YuBb7qbyPYgpAdfKzVrD0v8TYL41l9UFo1pj2iwrMQMX93ddPYie+wPyDSfE7qb
            wPag2hs4qNoI3J82jxG4r0rvO7a7aU2HXvVgF8EaX+EwoXGHruuz0+UTUvs/IeTcGl/wx91Ncy+y
            ww9K0q6muLBaD7zhVmf9xgiMChmBt7ubbid4u5uAXnQtvvEVDtcs8TdNymtLmjeudlNmTSS8anc9
            MEiCvr7PgOJB2zd8t84IjIxK5kmBV0jtfojlCaFNRMbmlpp1K3Zmm3qZ9geMKl/gUE1yraXFzipp
            rv/abbmDIRYS4sGQHnhVRKJmyAgOjknWa9K6TpjmqqhuXKUJEZYWDyC0u6v14Aoh5dOl0fCy7m7z
            rozvpXrwD/CEfMGDQ3rgzmo98EZID9y+muLCjta7ikCBU5oEb5Uv8JNqI7ikZidtUvRK2h8A1pLX
            N6YblwEHYfFOFM8Tw8yNVwg1wDqMvQhvcUoTEKU5vCikFw+zhKjv7r7YlfG9krTVevDZGl/gUNlN
            dveNOcHBISP49M56Xq+k7aGQIGr0vmdLYc3Ew7FljeG1qfKF9OCrIBtLmsOvdxetjTHukULr0g0J
            KaVWWVk5WQgxoddO2wOxikBBjRGcL7B20yTziHJ4qnzr/EVDEYRiunZ+d9FaowfO0JArypo3ul7o
            uYWUUlRWVh5YWVl55/tPP12lmZG3pZQNvZK2h0FJWHEzUr6z3gw/2V8vPkOg/Rz4W3JeK+Y5V8Li
            YQ2bvu02ggWTpBRTaozASCLh0zqjymXLlu0Vi8VO+ffzz0/PX7J0RO4b7wCw4b6710cikb/1Mm0P
            ggStRg/OlkJuLIuEHwsZxT9HipvQOC1lXhhXZtb+vrvoXQmGBvdGpXhCII+oMgLXDI2Eb2lPXZWV
            lUMsyzpZr6ubnvPCi2Nz33wH30efgVRrydqbrkJo2p3Tpk1r6mXaHoSQNzAO2Lc0Ej4BQEjvOkls
            m5CcFzKCpyFlbcSUt+zO5vq1er/RYH2ys2mUoIVyAoOIifECeVIM0SCEFZRC1AvJKRJudWu1qKys
            DAghjte21J3qW/TG1D5v/q/mX/4RRGOt8pl7DGV7RfnawQMH3ge9C7EeBamJgQIq4y99iLnxY2Ck
            gOhaf3FZTGpHGjrXrDT73Six7pDEfrezaAvp/faXxG6pERQQE+sEcqkU8tGy5vCrK8HI9wUOFlJ7
            JRPDLl68OM8wjKO0poZTc95+5/Dcxe/q/qXLEc2mY5n6s05DaNoNw4cPb4Jepu1RkFLUCGFNlyAE
            SJsBogBDmjZXS3gwpAcWFeixa0AsLTPrulzSru/Tf0AkEpuHsOqFh/NKG8M1yXn2hQjN4dec6li5
            cqVRX19/mBaNnOp//6Njc99+NzfnnaWIhqaMz4+M3puG8okr1tbUPBL/rZdpexCGmZs+DOnBonVG
            cASR2v8kpwuI1pjmCXiNkyxdLsRsz1Pco8YbPN80o6cKPBeXRjZ+nG35ysrKHBGJzGxe/Pb1wXcq
            AzlvvYtWv9V9BZpg84Vn4/F4LjnppJN26Ay9TNvTIFgvpbU/8J9UySVsrSXK/Ur+dh2UhI0eWRoJ
            TxMQa08dQ6YePUogJ4M0EcISWbrCbv/ZIURGjHhy0sSJrY4D9dppexhkJOd8C+3EkF40ujvpiDab
            u2uCyvYyLMBQc9MHZWbtiWVmeKAGV2RT1irMp+6cMzcLIS5NTutl2p4Go+FyBAO9msxiHu1crGGo
            3xLaLITolJgI1b5+e1jIOdmUqbvo18ji4lnl5eUbktN6mbaHQYCpWfx9UHP9mu6iQfNuO1MgXi1p
            rn2jo3VJ8CKtxwSij9syTeVj2f6TaS+Vl5cvSElfd3VML1KjJBK+3RJydLURXLDaX1zWHTR4sT4B
            uV9n1BXSgzcIKHeb3yrMJzzrwo0ej362U55epu1hEGCVmeELkNZdRkz8udoI3P8VA/rvrOevZVCu
            hbavRA7tcF2+wGFC4N6RRgjCl18kI4HArydMmLDeKVsv0/ZQlJmbV5aa4WOweMQwzGc7w5k7E771
            FQ63jOZXhJBNMTP/5I7Utc5fNDQmxX+RBY9tO+7nNFSU/2nKlCkvpsuXzuS1J3AYMBLIT5G+Bfgc
            WAR82UX9+H1HHnCJi3wLgJpUCWXR8L+r9eADui4eWmMWnjOc+rquIFSCt8by/tXStBlDIxtXQ7jd
            dX3LgD5Ry3xeQNBtmcg+e7H53LOW+Hy+qzLlTcW044DbgWm4cyqWwJvAlcAHKdIF8DKQ05FOTcAp
            wL7Ate0oGwGqgUrgBaC2k2hywgTgJhf5viWFF1ccZWbtEyEj0N9jeGcS4XPgMpfPXwWc45BWCvw9
            /mVKTk7+w0Lst1fDxt8Av21vgyVoIT36mECMcVsmFixi0/VXrPXk5Jw4bty4rLdMrkBtG8p2fEzg
            8hR1CmBrO+tM9RkOnN4J9TQAfyT1LNJZJxd+75KW+ZkqqqYgEDICb3vhvCzb6DSbjkjMe0ufInl9
            3/5SE6JDcb1CeuCukBGUbj/VeQPlBw/O375s2bJxbp+RqG/cgJKwHreFk+AF7gBmt7P8zkYOMAs1
            O4zoomdMdJlvQqYMZWwJI8Xi0zTf4W4qTGijq02K56JNjG+KcVZe+1XnKqN4FkK4d+IRgvBVv7Ga
            9hl1xoQJE953WyzOtEcA17eb2taYDfysk+raGdgTFZurw6vlJGi4N/WMROm/adFseO8708gdX6Lr
            qZKdptXxbgj4oLmJhdEIlrTa1dhqPTBDoN2VTZn6835FwyEHX1ZRUfFsNuU0QAfmkll/rQM+BjZm
            yCfs+r5Pfg2DgKfoXGvKcKCvy7xeXEjbPbZv+O4vwrx5rr+QYYaRnOzk8eWKaQE+lCbHSL1gA/0y
            DqBEVOt9T0WI+SKLg5VbTz2W+pOOv2PSpEl/yuZZ8c46GtgtTZ5G4FLUQiGKerHHA39FRRdM2b92
            vc+5oGEu8FUWNGdaPEVovVDRgVEoPTTd3DcRpSs/lm0npqkvFZoBn0P+jDtQzzZui1X5Y1yi9+FS
            00TKHe6r7wNjaMs4rpn2g+YmXtC8/cboxguYHOqmTJVefDJCPiayUCu3H3MYdefMeLCioiKjpcAJ
            j9G+RUc56Rdt8cC9mRZiU9tBc7qF2HaHMn2B1zO0dbmdtzMWYnMdyi50+P1FF3UCzBQg5+YVy/39
            /sTyc4BQinpNIDdFPSNITcfiKiPwZLURmP2PDIwY0oO/rDaC0WwWXv859kS5dMmSBbNnz273rKah
            RqcTPgKc9I2lwEtpyro2eewkbELFnE0n1ceiVIXOgJM+69SfB+FyepXAm7EIU7U2KkKqsERe4MBs
            CC+LhKcD1iQjcJxTnmpf4GIpeDQrCXvUT6i97LePTqyoOGvOnDntU55RTNsvTXqmFV26dLf63M7E
            duDONOkCl6vtDMhFSetUeBnYluL3/ig92BUWNjVwiC8Hj2jF58sdsrtWEQA2U1yAFBNjgrrkNAki
            pAdvFlLMzUaH3XbS0YQvvejB1xYtOksI0W6GBTUK0y2YMhl6m9Ok9dQo45UZ0gPAejcVpcEYlC6d
            jO9QC9nPSc1I5YCr+AGWlDzXtJ0zcvJ4uGGHF2OnMO02XZYJQQGWuLzKCMzQJDWlZviKVeBbawTm
            A+5vxBFQf/Zp1E8/+c6Kioor6YRQTbui70FjhvTOGGxOi7DP7L+fZlkuJZ6JNHKU14/fs+M1fkhq
            psiKacvMuk9KI7VTyszaw4jELpQwKuQLTssxAoskwjXDSt1L+MrfWPXTT768oqLiCjqBYaHjZqlt
            wDqHtO86g8DvKTIx7WdZlkuJxliMdyNNTDL8vNnYAMos+QWwd1LW3YBiYHO2DRlK/eYQfRci5ZvZ
            jGerII9Nsy9viowbe2ZFeXmnxvnqqKS9Dxji8OlpC7E4ulptEbSfaUcD/mwe9gomJ2itLGipVASN
            LKVtHNV6YAbCuiebMtFhJXw37/YNjWMOOKS8kxk23phdDYMzpHf0yOBgoMQhLRPTGigLhmusbGpC
            aoLT83eYoDtFr/2WAX1CRvBhIcTDIFw7OzVOnciGeXd82DRkyEGTJ09e2pGOdMKuyLSnZEhf56oW
            Z6Sb4uO7VtUoZ5Zsy6fETU1buTJmHCGV+anDTFuj9x1j6ub7wAy3ZaTXQ90FM9h4w9WPWXn5k6dM
            mVLdoV5Mg56w1XoBcJTLvL9H7Xi1Fz/G2VUPlDXk/1A7eu2FE9NtRNmKQZ1w/QzlBuq2vCPWmyZz
            2Z5zvR74A2b4epTVJ9l64cqLarTPP1pK+Z5AGG7yA0QHDyB8zaxGc9TIS4ZNO3rD4Eht5igcHUBP
            YNqTssh7Pe1j2gAwE+XMk67N/4PzjppbODHd50nfO41pAR4zG164zsiZirKOfELbDYUhqI2TtBEW
            C7yeQDZBQJrH7AMejcCd877zrKk+2RLk1+iBQsxwZ22Ht8EPUT3IQZ0CiH/WoaTcbaRf5Fgo18yO
            QMdZJ/0sw/c4SlAMlmWjhSFArPMGx+GsImR0ynELK1DIphuvlBvuvu2v0X0P7Dds1Ue7lZnhH9dH
            wpMRnJWt00026AmStrMhaMdLRzkAdfR2ltE4n9BIZtJP09QzEfjvbB5ch2xo1r2/MCLR+87R/L75
            VsoZejzqxEb7IaDh0B+x+YKz18pA4JyK8vKXE5P3hUgV4o/NunU1Jtd0sD9T4ocoaduD11CebB1F
            uqk9mWk/b2c9jthj+4bvPKbxq2M0Y1+HLOMBQjmBkue9hTdmW3908AA23n69rL3m8kcac3P3LU9i
            2DiGRmpfBDn6S39xafu70hk/REmbLR4CLqJjC7w4nJhN0tbftQpoIrXK0i6mjWOU8EZyPFqsMWYl
            O7OMrfIFbyAmLysW7oNnSJ/O1lOPY8spJ3wV1fXzp0yalPF+Bwtttt+Sc4CzOtqpyegJTHsnahfH
            DTqDsUDpr28Bt6AOZXYWnDy7wrR1no+ipG8qD6wDUe8ma5vxEL5tqKbvDc/mBO47v7m+qNpstaoK
            1Oja7LKIS38VIWg8uJy6mWc2xgYPvqOpqekP06ZMcWUZGGZu+rDaCHqq9OJ9hpqbP3VTxi16AtO+
            BHTmPawRnE/qxk/jLqXjTjHJ6IezM/3npN53d2LauJfYB7QDZeamJ1/PGzR+JnmXXmu23rn9yIpS
            5kIrjOy3N3XnnSmb99nnn7FY7HdTysuzvwjEkn/RhPgV6qR2p6EnMG1nI0p698OuQjp/WCdLQToJ
            VE47mXYV+G5s3Oo90shhd8NgdaRlgvqYKEfjbIKNFRay6XeX0zDxoA89Hs9lyWE2s4LgZCG0Dp3u
            TYXehVjnIZ0e6sScn6Upk7V5ajXFhdVG8LIcPbj6Ak/OxY+YDUz3tjZmfGyl1zjC+fmbtk8Yf/qk
            SZPGTewIwwKaYExJZNO/OlJHKnRU0h4JnOqQVo/a7dpVkI3lINPvmeprg9P9fU7ULXGpgHwElEQl
            y6XJzwyDH+fk8obyAmNl1CTmdY46+8UXX6ycPHnyE53RIVKK5o7X0hYdZdpRwHSHtPXsOkzrIb1k
            dJK0a3C2IOyGCivkKgrOQK8+UkRatBMhJftrXm6MNnCbyOEN29VhezTKV3keYiNGwrL/7bIOWcNQ
            f+oDGh1Hr3rQOdib1JFqQPmwOm2dxnAIU49D3IQSNNfRNPbXvMzw+PhSa70GfOvsX/LdRTO7NP6t
            19hyiBBiUZfU3cHyPfVIzc5Guqn88wxlPwUOcEibAPzra3/RUMPynCiR05+wmsdcFXXnHnGA8FJl
            xXhGtrYU/nXBgmc3bdp0Lc4DpsMQeDSJ1SU3Q2ikl+EDMpQfmCatS/SZHor2LMLicNRrd/Pqp1Qb
            wfe8lmeNhDtxG9RNqAvjhp5+CsukyRW+1m4AmzZt2r2rO0TCF1Lyi66o24s6SOcUEugnqFO1m1Kk
            +QHHI8bA6q7umB6EdOGPPstQ1jG9VrAHmthDWO6OVpm7ldL4owoaDpmCWVoa8wnx+uuPPDR2hNnc
            98q8Im7fVhfPug9ZnpDIFlLKe4SQd3RF3RpqZ8gJ+ahwkMnBHrwoB5N04dXT1ftDQiFtz2QlIiVT
            SvBU6X3H/lefgKOUrjdNVnvdaWBbjj+G9Q/fR90Z01eYZWVXIsTQSZMmHR6JRJb8qWELIzWdMb4d
            5i+DLjwOVeULHCaErC6NbH6547W1hRd4AuWn6hR04aeoffMHUPFOy4Bfkz4+QJSE2KcZ8CjZ+bAe
            1hUd0QGMI33Aik8B1pLXF58+NibFRCFFeY2gXEMWTLI0/B4PTbHUNqgPLZM94tULkDl+2Nq2uz5b
            vfplTdMumzhxYvIgWQ4cO6thM7fmFPKZ12Du9npo55mxTKimIIAUN3p179GdtumeBC9qGn+Y9B79
            u5Gdr+nDKHOOG2QbrdC1R/1OgqOkLPB6rZWicC4wNiYoExIVWiNBeHpikj10g5Wx1Cfb38/zc8Tk
            qTTvtw/NY/ajbul7cHvbV7FkyZLlKRgWbN/acDTKeVtrmV/Yjwqfn3ebm7qEaUvZUlcjgwXRZnM4
            XXQiO249uAIV+bsjx0zi+JIsLzr7nsORaUd4vJqQafV+APZCY6VD2vKCPMKX/RYpZbUQ4vUVK1YY
            qFhmbvE+yu9BAFy2LcyC3GKkJkYva24i5lJfdgsBVg08bwm5Hx33T06JONPWoWLKLgKGdaC+b4DD
            UbthuwLSHRdnT83bZudJ5viIDupPdMhgoiWDiQ4tYdDKFfBc6gCTX3/9Ne+9997YWbNmfWj/NJPs
            mHYzKn7ZngD1sRgzG+s41+jjuzIvj7nm9h27ZZ2Bar1oPyk4tDSyeXLnd7dCop32K5TTx4PAsVnW
            I1Ee8eeSOX7tDwm7kyZmWVn5BOrGHUSsb5Bov77EBvYnVlAIreNvMdinb+e551L6t1qWxaxZs5xC
            qrrFcmymBdgYjXJztJ4hXp3f+frw64I+XNpYxwaz4zdES1PWaAZVInNIrXYjeXNhI/ALYDIqtv+h
            pLfVbkBJ5weAJV1FZA9GWqeWgUcfxdaDDkr8abOU8kuk/A/KqrBSCPHRnDlzCsjsh5DR8ToNlpNi
            u31t1GRWtI4Rho/7/YXM9zSwONJEk9X++HBDqd8ckgH/Kgb224v1XSLA3NhTAkBBit/rcR9mZ6jL
            Z7lBDcrG6CThJOpUQEdgkD6ox0aUxaMIKHrqqade83q9JbFY7LtoNLo+Go1+G4lE1g4fPjyUk5NT
            pWnaGinll5MmTdpMar9ajfTmw2202MrzSX3VUZ39SYVcVFRGR5xeUOC9rUk/vtkjjjaicrHXE5s/
            uKmuXf0Y8gYmSE38UUj5YGkXnsoFJXX3J/0uV2ch0e4rSB0Z2wkdvdopnVHdLS159scAWLhwYa79
            /8705eisK64SoQch/y2j6KiV/sCbX+X2nb3GCIxsT0UhI/BEyOh7RMJPGulNg36yFGx+4FXgauAf
            ZBPKURmpD8oiPyhJFTd1DQTucVnOAP6Z5bOSkS6kfn9UBO900FB263tQ9zTEab8B55i0XYH/ySLv
            QNRlMJlwISqE/nxg/pycgvNCevCVam8g8f3uDs6h7SV4QkZw/i3+ghW0diK6B0h33+7DpI+V3Aan
            2gSDkrhvJaT1oa10KqJFIh1L61BDXtSI0VCR+lLhfdT1TfEOvcf+34NSRxJHXC6tb35JlGapaMtJ
            6iyD1nctODHtQNTR80SmLabt6NdQzBrHg6iL5G6ghWl9tL3fIVU7kvuniLaRYfrSIqWCSeXj8Nt9
            kYi8hN9GQKvj3Kn6DRQPtAqeUk1B4EMj8OaXOX0fl4q2clpfjuexadHy8/OD1b5+M0JG4Leodxpn
            wnyUS0AiCpJo0JLqTFaB8ux+3bEQG4/idFC7WfGLNo5CLcwKUeeu8u2X84ld5ki7kcUoSb0EtWt2
            LupikTU2cWcmEfANijkTX2KhXWY5alt0BkqCX4fSHx9EHUJ8yn7m8ajB5gP+ALyLGgxv2+WvQ50J
            u9p+3nbgVlLjdrsvSlCLS4B5qLsihgKn4RxbdVtS549FXS36LSpQyFxUIOd3Uf7HV6EWXU/Y/eOx
            +/telA/yZPt5Z6DOj21AMV01agB+CdwFPAmcjPIQ+z3KQelx4D3gFft5B6AW1KeidhKfR+1kHoca
            RDcC/yYNythyFhHeH2IYU5/xB5YfF2soeM5sCqGsRY+j1jVXAjf9KBLznu9tPHTfiDXZfufNNm3z
            UeuMn6OO6p+AEnZhlIn1aOAvqMEgUIJlFcoCcYXdF4fbPLJj8C0gtWiOX8g73GaMiTYzYHf+eFpL
            2nhkk+OA8+3//0TbvflngQrgYlok7TBabJ6P238fRVkvcmkZSC/Yf1+2X3gREA8nGb8DYordARWo
            BY5IyJMsaX0JdU62mWyQTTd2pydOaxpqYBwP/AZ1wYegRdJOtduk2+3QbGYBtYFzMXAILTuQC1BM
            f5r9/XK7nll2O+L9FRcwzyS14y8oAWDY76sQNSDi7+GXtJa0r9j91i8hXxwXoi4yuRN1UlmghIQP
            GJULY5/rE6ilRdK+a+cZDEyt8vXbfVp+XvzA6D32M35Ey2GAv9nv8p92ewxaNiAesr8fiTrSTxLN
            8ecsSBTJqZTg4cDN9kPjU258R7mJtiazeKS8PWHHJs9KYK8Udb+LMhnFafgGJZnm0OJfWoySNA0o
            6ZJIq0CZ7utoWZTEjzc32y/mXdQ082ect38HoW6FgZat52EoxrkVNZCSB3T82UtRl48kSuG3UerC
            vIS2xfdoIzYdw1GS71a7r4pQjD0TJVXiiLt3NtBynDzZHlWGGtA30DJzmQl/k9UNadO+kdRWoRdQ
            IaTusvM+gRoongb4oFmwbqrhH7VKMfJaO8+6u/sU6ndrzcuWNTQkL0aHowbprSg+CNrvJkrL6ehE
            7EGL+e8Wuw8j8ecAgTjTfYfS5+J7xTehgrVtoLUu5PawnUh4kTu2EFPgX6gpAxST3ISaPlJd+9mH
            7IPDTUeN9OtQEikTrYl4lRa9Ozl9Hc7hheLORLNxPhUsUA5FC+3vOmqW+ANtpV8mWLR+RwVZlk/G
            NlpfQf4iSr2ZD8y7uGnLhT/TjGtz9MAz/tjmWJPaBh4xr3Hb3Dtyip67N2YlmyIFagaIzzbZ7Bvn
            ogZuqzLxUfEeLYpyH9S0b9kfgRot2dx5uxp1dSb2X6drkJ6FHY7C01BTympabLB1KPUgD7g/oVz8
            zisN9ZKc/IkOR+lEEVJf3AFqYMZjfw2z/4ZQ0lLa5Udl0fafoqb4OH2pUIXSnyVqY2ZPYAVq5Z7V
            ChqlO/e32/ewi/zxRXIAd0LgQtQ5tSuBwzdFo+bjkYaXhCbuPbAg74CQETjuFH+fq2b58vqMj4gH
            aFlcxhmtKqEv70OpQlGbXp22AahXJ/T3Q/bfuDlsAFAfl7QLUaauP6NEeFzCPI0aYUWo6cdPC4OY
            qGkmhBrpL9MyPb+ECqA2FMUQyd778Wkvar+ogahFXPxe3f7AvihGfZCWhRgJz/g7Sh/MtTsjsV7L
            pu9N1EBosukI0vZERQNKct5o5wmjNjDyUarKSNpKzFRRViK0RK75s/2SdkcNqvgzYzZd76D04UEo
            1eErlEp0HcrVsSqhfxPblfjs+G8Po1SRBtQ0LpP610RtTExD6adPo3TLItQt7ImIohbRcfPYH1F6
            5K12W+bafXV4SXPtM/1j3k+FMKbsLb3vXBPZutdFlnUCatCNRal7F6Km+EtQgsiy6X8MeAQl1eP8
            FKd5EUoql9rvIWZ/n2/XcVtyxw+krSmkmNQ3/iUilRTz2C8lG4NxEYpZNFqkVD7OU14RmS9C7o/S
            vzJd0jaA1pJRtIP+xLqMDM/00nojx0fLrlW2N8Hnk/4K1fjz4m0pdtFvye1JNKnpKfqlwKYh8d3F
            +cKT1NZpdluLUVaQZHho6z4QoK1Zrxe92Gk4BjUrPE32zln8P6RS4nJMJTV5AAAAJXRFWHRkYXRl
            OmNyZWF0ZQAyMDIzLTA5LTA2VDE4OjI2OjQyKzAwOjAwOZ3bxQAAACV0RVh0ZGF0ZTptb2RpZnkA
            MjAyMy0wOS0wNlQxODoyNjo0MiswMDowMEjAY3kAAAAASUVORK5CYII=" />
            </svg>
        </a>
        <button class="layout-sidebar-anchor p-link z-2 mb-2" type="button" (click)="anchor()"></button>
    </div>
 
    <div #menuContainer class="layout-menu-container">
        <app-menu></app-menu>
    </div>
</div>