import { IRolUsuario } from './../interfaces/sesion-usuario';
import { Injectable } from '@angular/core';
import { ISesionUsuario } from '../interfaces/sesion-usuario';
import { TrabajadorService } from 'src/app/ModuloVacaciones/service/trabajador.service';
import { IRolResponse, IUsuarioResponse } from '../interfaces/seguridad-usuario';
import { Router } from '@angular/router';
import { ROL } from '../interfaces/enum/rol';
import { FiltroService } from './filtro.service';
import { environment } from 'src/environments/environment';

const URL_LOGIN = environment.web.login;
@Injectable({
  providedIn: 'root'
})
export class SesionUsuarioService {

  private readonly EXPIRATION_KEY = 'token_expiration';

  constructor(
    private trabajadorServicio: TrabajadorService,
    private router: Router,
    private filtroServicio: FiltroService
  ) { }

  guardarSesionUsuario(usuarioSeguridad: IUsuarioResponse) {

    let listaRol: IRolUsuario[] = [];
    usuarioSeguridad.profiles.forEach(item => {
      listaRol.push({ codigoRol: item.proCodigo, nombreRol: item.proDescri })
    })

    //buscar el id del trabajador, y dependencia del trabajador
    this.trabajadorServicio.listxNumeroDocumento(usuarioSeguridad.usu_docnum).subscribe(
      {
        next: (response) => {

          if (response.success) {

            let dataTrabajador = response.data[0]

            this.grabarToken(usuarioSeguridad.sToken);
            this.setTokenExpirationDate(usuarioSeguridad.dTokenExpiration)
            
            if (dataTrabajador != null) {

              let idTrabajador = dataTrabajador.idTrabajador!
              let idDependencia = dataTrabajador.idDependencia!

              const usuarioSesion: ISesionUsuario = {
                idUsuario: usuarioSeguridad.usu_id,
                usuarioLogin: usuarioSeguridad.usu_login,
                idTrabajador: idTrabajador,
                numeroDoc: usuarioSeguridad.usu_docnum,
                nombreCompleto: usuarioSeguridad.usu_fullname,
                idDependencia: idDependencia,
                correo: usuarioSeguridad.usu_email,
                codigoRol: listaRol[0].codigoRol,
                nombreRol: listaRol[0].nombreRol,
                listaRol: listaRol
              }
              this.grabarLocalStorage(usuarioSesion);

              if (usuarioSesion.codigoRol == "PRO-003") {
                this.router.navigate(["home/vacaciones/programacion"])
                //['vacaciones/programacion']
              }
              else {
                this.router.navigate(["home/inicio"])
              }
            }
            else {
              console.log('El DNI del usuario no existe en la BD.')

              const usuarioSesion: ISesionUsuario = {
                idUsuario: usuarioSeguridad.usu_id,
                usuarioLogin: usuarioSeguridad.usu_login,
                idTrabajador: -1,
                numeroDoc: usuarioSeguridad.usu_docnum,
                nombreCompleto: usuarioSeguridad.usu_fullname,
                idDependencia: -1,
                correo: usuarioSeguridad.usu_email,
                codigoRol: listaRol[0].codigoRol,
                nombreRol: listaRol[0].nombreRol,
                listaRol: listaRol
              }
              this.grabarLocalStorage(usuarioSesion);
              this.router.navigate(["home/inicio"])
            }
          }
          else {
            console.log('No se encontro ningun registro en la BD.')
          }
        },
        error: (e) => { }
      }
    )
  }

  grabarLocalStorage(usuarioSesion: ISesionUsuario) {
    localStorage.setItem("usuario", JSON.stringify(usuarioSesion))
    //console.log(usuarioSesion)
    this.filtroServicio.deleteFiltro()
  }

  obtenerSesionUsuario() {
    const dataCadena = localStorage.getItem("usuario")
    const usuario = JSON.parse(dataCadena!)
    return usuario
  }

  eliminarSesionUsuario() {
    localStorage.removeItem("usuario")
    this.eliminarToken()    
    localStorage.removeItem(this.EXPIRATION_KEY);
    this.filtroServicio.deleteFiltro()
  }

  grabarToken(token: string) {    
    localStorage.setItem("TOKEN", token);    
  }

  obtenerToken(): string {
    return localStorage.getItem("TOKEN") || '';
  }

  eliminarToken() {
    localStorage.removeItem("TOKEN")
  }

  setTokenExpirationDate( expirationDate: Date) {        
    localStorage.setItem(this.EXPIRATION_KEY, expirationDate.toString());    
  }

  getTokenExpirationDate(): Date | null {
    const expiration = localStorage.getItem(this.EXPIRATION_KEY);
    return expiration ? new Date(expiration) : null;
  }

  getIdTrabajador(): number {

    const usuario = this.obtenerSesionUsuario()
    const codigo = usuario.idTrabajador
    return codigo

  }

  getIdRol(): number {

    const usuario = this.obtenerSesionUsuario()
    let idRol: ROL.USUARIO = ROL.NINGUNO.valueOf();
    const codigo = usuario.codigoRol

    if (codigo == 'PRO-003') {
      idRol = ROL.USUARIO.valueOf()
    }

    if (codigo == 'PRO-004') {
      idRol = ROL.RRHH.valueOf()
    }

    if (codigo == 'PRO-005') {
      idRol = ROL.OGA.valueOf()
    }

    if (codigo == 'PRO-006') {
      idRol = ROL.JEFE_INMEDIATO.valueOf()
    }

    if (codigo == 'PRO-007') {
      idRol = ROL.DIRECTOR_EJECUTIVO.valueOf()      
    }

    if (codigo == 'PRH-008') {
      idRol = ROL.ASISTENTE_JEFE_INMEDIATO.valueOf()
    }

    return idRol
  }

  getIdDependencia(): number {
    const usuario = this.obtenerSesionUsuario()
    const codigo: number = usuario.idDependencia
    return codigo
  }

  getListaNombreRol(): string {
    const usuario = this.obtenerSesionUsuario()
    const lista: IRolUsuario[] = usuario.listaRol
    let value: string = ''

    lista.forEach(item => {

      if (value.length < 1) {
        value = item.nombreRol.toString()
      }
      else {
        value = value + ', ' + item.nombreRol.toString()
      }
    });

    return value
  }
  goToLogin() {
    window.location.href = URL_LOGIN;
  }
  closeSesion() {
    this.eliminarSesionUsuario();
    //window.close();
    window.location.href = URL_LOGIN;
  }

  isAuthenticated(){
    var infoUsuario = this.obtenerSesionUsuario();
    return infoUsuario!=undefined || infoUsuario!=null;
  }

  goToWelcomePage(){
    this.router.navigate(["/home/vacaciones/welcome"]);
  }


}
