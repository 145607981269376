<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>

        <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
    </div>

    <div class="topbar-end">
        <ul class="topbar-menu">
            <li class="topbar-search">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText placeholder="Search" class="w-12rem sm:w-full">
                </span>
            </li>
            
            <li class="topbar-profile" (click)="onProfileButtonClick()">
                               
                <button type="button" class="p-link" style="margin: 12px;" >                    
                    <img src="assets/images/icon_avatar.png" alt="Profile" />
                </button>   
                <strong>{{login}} / {{rol}} </strong>               
            </li>
        </ul>
    </div>
</div>