<div class="card">
    <h5>Cambiar Rol</h5>    

    <div class="grid p-fluid mt-3">                   
        <div class="field col-12 md:col-12">            
            <p-dropdown [options]="listaRol" [(ngModel)]="selectRol" 
                placeholder="Seleccione Rol" [showClear]="true"                 
                optionLabel="nombreRol"                 
                appendTo="body"
                >
            </p-dropdown>                                
        </div>        
    </div>   
   
    <div class="flex flex-wrap gap-2">                    
        <button pButton pRipple type="button" icon="pi pi-sign-in" label="Cancelar" class="p-button-secondary" (click)="cerrar()"></button>        
        <button pButton pRipple type="button" icon="pi pi-save" label="Grabar" class="p-button-success" (click)="grabar()"></button>                
    </div>   

</div>
