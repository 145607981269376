import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SesionUsuarioService } from '../services/sesion-usuario.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private _sesionUsuarioService: SesionUsuarioService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this._sesionUsuarioService.isAuthenticated()) {
            return true;
        } else {
            localStorage.clear();
            this._sesionUsuarioService.goToLogin();
            return false;
        }
    }
}