import { IRolUsuario, ISesionUsuario } from './../../shared/interfaces/sesion-usuario';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SesionUsuarioService } from 'src/app/shared/services/sesion-usuario.service';

@Component({
  selector: 'app-cambiar-rol-modal',
  templateUrl: './cambiar-rol-modal.component.html',
  styleUrls: ['./cambiar-rol-modal.component.scss']
})
export class CambiarRolModalComponent implements OnInit {

  listaRol:IRolUsuario[]=[]
  selectRol:IRolUsuario={codigoRol:'',nombreRol:''}

  constructor(
    private modalActual: MatDialogRef<CambiarRolModalComponent>,
    private usuarioSesionServicio:SesionUsuarioService,        
  ) {

  }
  ngOnInit(): void {
  
    this.cargarRol()
  }

  cargarRol() {

    const usuario=this.usuarioSesionServicio.obtenerSesionUsuario()    
    const lista:IRolUsuario[]= usuario.listaRol
    
    lista.forEach(item => {     

      if (item.codigoRol!=usuario.codigoRol) {
        this.listaRol.push({codigoRol:item.codigoRol,nombreRol:item.nombreRol})
      }
      
    });    

  }

  cerrar() {    
    this.modalActual.close(false);
  }

  grabar() {

    if (this.listaRol.length<1) {      
      return 
    }

    const usuario=this.usuarioSesionServicio.obtenerSesionUsuario()    
    const lista:IRolUsuario[]= usuario.listaRol
    
    const usuarioActual:ISesionUsuario ={
        idUsuario:usuario.idUsuario,  
        usuarioLogin:usuario.usuarioLogin,
        idTrabajador:usuario.idTrabajador,    
        numeroDoc:usuario.numeroDoc,
        nombreCompleto:usuario.nombreCompleto,
        idDependencia:usuario.idDependencia,
        correo:usuario.correo,    
        codigoRol:this.selectRol.codigoRol,
        nombreRol:this.selectRol.nombreRol,
        listaRol:lista
    }

    //console.log(usuarioActual)
    this.usuarioSesionServicio.grabarLocalStorage(usuarioActual)
    this.modalActual.close(true);
    
  }

}
