import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { SesionUsuarioService } from '../shared/services/sesion-usuario.service';
import { ROL } from '../shared/interfaces/enum/rol';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(
        private usuarioLoginServicio:SesionUsuarioService
    ) {

    }

    ngOnInit() {

        this.cargarMenu()
    }

    cargarMenu() {

        const idRol=this.usuarioLoginServicio.getIdRol()

        switch (idRol) {

            case ROL.USUARIO.valueOf():
                this.menuRolUsuario();
                break;

            case ROL.JEFE_INMEDIATO.valueOf():
                this.menuRolJefeInmediato();
                break;

            case ROL.ASISTENTE_JEFE_INMEDIATO.valueOf():
                this.menuRolAsistenteJefeInmediato();
                break;

            case ROL.RRHH.valueOf():
                this.menuRolRRHH();
                break;

            case ROL.OGA.valueOf():
                this.menuRolOGA();
                break;

            case ROL.DIRECTOR_EJECUTIVO.valueOf():
                this.menuRolDirectorEjecutivo();
                break;
          }
    }

    menuRolUsuario() {
        this.model = [
            {
                label: 'Vacaciones',
                icon: 'pi pi-th-large',
                items: [
                    {
                        label: 'Programación Vacaciones',
                        icon: 'pi pi-fw pi-calendar',
                        routerLink: ['vacaciones/programacion']
                    },
                    {
                        label: 'Re-Programación Vacaciones',
                        icon: 'pi pi-fw pi-calendar-plus',
                        routerLink: ['vacaciones/reprogramacion']
                    },
                ]
            },
        ];
    }

    menuRolDirectorEjecutivo(){
        this.model = [
            {
                label: 'Dashboards',
                icon: 'pi pi-home',
                items: [
                    {
                        label: 'Vacaciones',
                        icon: 'pi pi-fw pi-chart-bar',
                        routerLink: ['inicio']
                    },
                ]
            },
            {
                label: 'Vacaciones',
                icon: 'pi pi-th-large',
                items: [
                    {
                        label: 'Programación Vacaciones',
                        icon: 'pi pi-fw pi-calendar',
                        routerLink: ['vacaciones/programacion']
                    },
                    {
                        label: 'Ejecución Vacaciones',
                        icon: 'pi pi-check-square',
                        routerLink: ['vacaciones/ejecucion']
                    },
                    {
                        label: 'Re-Programación Vacaciones',
                        icon: 'pi pi-fw pi-calendar-plus',
                        routerLink: ['vacaciones/reprogramacion']
                    },
                ]
            },
        ];
    }

    menuRolJefeInmediato() {
        this.model = [
            {
                label: 'Dashboards',
                icon: 'pi pi-home',
                items: [
                    {
                        label: 'Vacaciones',
                        icon: 'pi pi-fw pi-chart-bar',
                        routerLink: ['inicio']
                    },
                ]
            },
            {
                label: 'Vacaciones',
                icon: 'pi pi-th-large',
                items: [
                    {
                        label: 'Programación Vacaciones',
                        icon: 'pi pi-fw pi-calendar',
                        routerLink: ['vacaciones/programacion']
                    },
                    {
                        label: 'Ejecución Vacaciones',
                        icon: 'pi pi-check-square',
                        routerLink: ['vacaciones/ejecucion']
                    },
                    {
                        label: 'Re-Programación Vacaciones',
                        icon: 'pi pi-fw pi-calendar-plus',
                        routerLink: ['vacaciones/reprogramacion']
                    },
                ]
            },
        ];
    }

    menuRolAsistenteJefeInmediato() {
        this.model = [
            {
                label: 'Dashboards',
                icon: 'pi pi-home',
                items: [
                    {
                        label: 'Vacaciones',
                        icon: 'pi pi-fw pi-chart-bar',
                        routerLink: ['inicio']
                    },
                ]
            },
            {
                label: 'Vacaciones',
                icon: 'pi pi-th-large',
                items: [                  
                    {
                        label: 'Ejecución Vacaciones',
                        icon: 'pi pi-check-square',
                        routerLink: ['vacaciones/ejecucion']
                    },
                    {
                        label: 'Re-Programación Vacaciones',
                        icon: 'pi pi-fw pi-calendar-plus',
                        routerLink: ['vacaciones/reprogramacion']
                    },
                ]
            },
        ];
    }

    menuRolRRHH() {
        this.model = [
            {
                label: 'Dashboards',
                icon: 'pi pi-home',
                items: [
                    {
                        label: 'Vacaciones',
                        icon: 'pi pi-fw pi-chart-bar',
                        routerLink: ['inicio']
                    },
                ]
            },
            {
                label: 'Vacaciones',
                icon: 'pi pi-th-large',
                items: [
                    {
                        label: 'Configuración',
                        icon: 'pi pi-fw pi-cog',
                        items: [
                            /*{
                                label: 'Registro Jefe Inmediatos',
                                icon: 'pi pi-fw pi-inbox',
                                routerLink: ['pages/empty']
                            },*/
                            {
                                label: 'Registro Fec. Inicio Vacaciones',
                                icon: 'pi pi-fw pi-pencil',
                                routerLink: ['vacaciones/configuracion']
                            },
                            {
                                label: 'Reprogramación',
                                icon: 'pi pi-fw pi-comment',
                                routerLink: ['vacaciones/calendario']
                            }
                        ]
                    },
                    {
                        label: 'Plan Programación Vacaciones',
                        icon: 'pi pi-fw pi-book',
                        routerLink: ['vacaciones/plan']
                    },
                    {
                        label: 'Programación Vacaciones',
                        icon: 'pi pi-fw pi-calendar',
                        routerLink: ['vacaciones/programacion']
                    },
                    {
                        label: 'Ejecución Vacaciones',
                        icon: 'pi pi-check-square',
                        routerLink: ['vacaciones/ejecucion']
                    },
                    {
                        label: 'Re-Programación Vacaciones',
                        icon: 'pi pi-fw pi-calendar-plus',
                        routerLink: ['vacaciones/reprogramacion']
                    },
                ]
            },
        ];
    }

    menuRolOGA() {
        this.model = [
            {
                label: 'Vacaciones',
                icon: 'pi pi-th-large',
                items: [
                    {
                        label: 'Plan Programación Vacaciones',
                        icon: 'pi pi-fw pi-book',
                        routerLink: ['vacaciones/plan']
                    } ,
                    {
                        label: 'Programación Vacaciones',
                        icon: 'pi pi-fw pi-calendar',
                        routerLink: ['vacaciones/programacion']
                    },
                    {
                        label: 'Ejecución Vacaciones',
                        icon: 'pi pi-check-square',
                        routerLink: ['vacaciones/ejecucion']
                    },
                    {
                        label: 'Re-Programación Vacaciones',
                        icon: 'pi pi-fw pi-calendar-plus',
                        routerLink: ['vacaciones/reprogramacion']
                    },
                ]
            },
        ];
    }

    menuRolAdmin() {

        this.model = [
            {
                label: 'Dashboards',
                icon: 'pi pi-home',
                items: [
                    {
                        label: 'Vacaciones',
                        icon: 'pi pi-fw pi-chart-bar',
                        routerLink: ['inicio']
                    },
                    {
                        label: 'Planillas',
                        icon: 'pi pi-fw pi-image',
                        routerLink: ['pages/empty']
                    }
                ]
            },
            {
                label: 'Vacaciones',
                icon: 'pi pi-th-large',
                items: [
                    {
                        label: 'Configuración',
                        icon: 'pi pi-fw pi-cog',
                        items: [
                            {
                                label: 'Registro Jefe Inmediatos',
                                icon: 'pi pi-fw pi-inbox',
                                routerLink: ['pages/empty']
                            },
                            {
                                label: 'Registro Fec. Inicio Vacaciones',
                                icon: 'pi pi-fw pi-pencil',
                                routerLink: ['vacaciones/configuracion']
                            },
                            {
                                label: 'Registro de config.  Etapa Vac.',
                                icon: 'pi pi-fw pi-comment',
                                routerLink: ['pages/empty']
                            }
                        ]
                    },
                    {
                        label: 'Plan Programación Vacaciones',
                        icon: 'pi pi-fw pi-book',
                        routerLink: ['vacaciones/plan']
                    },
                    {
                        label: 'Programación Vacaciones',
                        icon: 'pi pi-fw pi-calendar',
                        routerLink: ['vacaciones/programacion']
                    },
                    {
                        label: 'Re-Programación Vacaciones',
                        icon: 'pi pi-fw pi-calendar-plus',
                        routerLink: ['vacaciones/reprogramacion']
                    },
                    {
                        label: 'Suspensión de Vacaciones',
                        icon: 'pi pi-fw pi-stop-circle',
                        routerLink: ['pages/empty']
                    }
                ]
            },
            {
                label: 'Planillas',
                icon: 'pi pi-fw pi-prime',
                items: [
                    {
                        label: 'Configuración',
                        icon: 'pi pi-fw pi-eye',
                        routerLink: ['pages/empty']
                    },
                    {
                        label: 'Datos de Planilla',
                        icon: 'pi pi-fw pi-globe',
                        routerLink: ['pages/empty']
                    }
                ]
            }
        ];
    }

    limpiarFiltroPage() {
        
    }

}
