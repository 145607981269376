export const environment = {
    production:false,
    urlBase:"https://api-recursoshumanos-dev.ceplan.gob.pe/api/",    
    //urlBase:"https://localhost:7001/api/",    
    urlBaseSeguridad:"https://api-sso-security-dev.ceplan.gob.pe/api/",
    web:{
        login: 'https://login-dev.ceplan.gob.pe'
    }, 
};

