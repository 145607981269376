import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponseBaseApi } from 'src/app/shared/interfaces/response-base-api';
import { environment } from 'src/environments/environment';
import { ITrabajador } from '../interfaces/trabajador';

@Injectable({
  providedIn: 'root'
})
export class TrabajadorService {

  private urlApi: string = environment.urlBase + 'Trabajador/';

  constructor(
    private http: HttpClient,
  ) { }

  listar(id:number):Observable<IResponseBaseApi<ITrabajador>>{
    return this.http.get<IResponseBaseApi<ITrabajador>>(`${this.urlApi}listar?id=${id}`);
  } 

  listarAll(idDepedencia:number):Observable<IResponseBaseApi<ITrabajador[]>>{
    return this.http.get<IResponseBaseApi<ITrabajador[]>>(`${this.urlApi}listarAll?idDependencia=${idDepedencia}`);
  } 

  listxNumeroDocumento(numeroDocumento:string):Observable<IResponseBaseApi<ITrabajador[]>>{
    const idDepedencia=-1
    return this.http.get<IResponseBaseApi<ITrabajador[]>>(`${this.urlApi}listarAll?idDependencia=${idDepedencia}&numeroDocumento=${numeroDocumento}`);
  } 



}
