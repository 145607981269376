import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { Router } from '@angular/router';
import { SesionUsuarioService } from '../shared/services/sesion-usuario.service';
import { MostrarMensajeService } from '../shared/services/mostrar-mensaje.service';
import { MatDialog } from '@angular/material/dialog';
import { CambiarRolModalComponent } from './cambiar-rol-modal/cambiar-rol-modal.component';

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent {

    usuario:string=''
    correoUsuario:string=''
    usuarioLogin:string=''
    rolActual:string=''
    rolUsuario:string=''

    constructor(
        public layoutService: LayoutService,
        private router:Router,
        private usuarioSesionServicio:SesionUsuarioService,
        private mensajeriaServicio:MostrarMensajeService,
        private dialog:MatDialog,
        ) { 
            const usuario=this.usuarioSesionServicio.obtenerSesionUsuario()   
            this.correoUsuario= usuario.correo
            this.usuarioLogin=usuario.usuarioLogin.toUpperCase()
            this.rolActual=usuario.nombreRol.toUpperCase()
            this.usuario=usuario.nombreCompleto.toUpperCase()
            this.rolUsuario=this.usuarioSesionServicio.getListaNombreRol()
            //console.log(usuario)
        }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

    cerrarSesion() {

        this.mensajeriaServicio.mensajePregunta('Salir','Desea cerrar el sistema').then((result)=> {

            if (result.isConfirmed) {
                this.usuarioSesionServicio.eliminarSesionUsuario()
                this.router.navigate(['auth/login'])    
            }
        })
    }   

    cambiarRol() {

        this.dialog.open(CambiarRolModalComponent,{
            disableClose:true,
            width:'30%'
            }).afterClosed().subscribe(result=> {
            if(result===true) {                
                this.router.navigate(["home/inicio"])
                window.location.reload();
                
            }
        })           
    }
}