import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class MostrarMensajeService {

  constructor() { }

  mensajePregunta(title:string='',message:string='') {

    let titulo='Operación'
    let mensaje= 'Esta seguro de realizar la operación?'

    if (title.length>0) {
      titulo=title;
    }

    if (message.length>0) {
      mensaje=message
    }    

    return Swal.fire({      
      title:titulo,
      text:mensaje,
      icon: 'question',      
      showCancelButton: true,      
      cancelButtonColor: '#f0ad4e',
      cancelButtonText:'Cancelar',
      confirmButtonColor: '#22bb33',
      confirmButtonText: 'Si, continuar'
    })

  }


  mensajeEliminar(mensaje:string='') {

    mensaje= 'Desea eliminar el registro selecionado?'

    if (mensaje.length>0) {
      mensaje=mensaje
    }    

    return Swal.fire({      
      title:'Eliminar',
      text:mensaje,
      icon: 'warning',      
      showCancelButton: true,      
      cancelButtonColor: '#3085d6',
      cancelButtonText:'Cancelar',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Si, deseo eliminar'
    })

  }

  mensajeInfo(title:string='',mensaje:string) {      

    let titulo='Operación'
    
    if (title.length>0) {
      titulo=title;
    }
    
    return Swal.fire(titulo,mensaje,'success')
  }

  mensajeEliminarInfo() {      
    return Swal.fire('Eliminado','Su registro fue eliminado','success')
  }

  mensajeAlertaEliminarInfo() {

    Swal.fire({
      position: 'top-end',
      title:'Eliminado',
      icon: 'success',
      text: 'Su registro fue eliminado',
      showConfirmButton: false,
      //showCloseButton: true,
      timer: 1500
    })      
  }

  mensajeWarning(mensaje:string) {  
    return Swal.fire('Advertencia',mensaje,'warning')
  }

  mensajeError(mensaje:string) {  
    return Swal.fire('Error',mensaje,'error')
  }

  mensajeAlertaError(mensaje:string) {

    Swal.fire({
      position: 'top-end',
      title:'Oops...',
      icon: 'error',
      text: mensaje,
      showConfirmButton: false,
      //showCloseButton: true,
      timer: 3000
    })      
  }

  mensajeAlertaOK(mensaje:string) {

    Swal.fire({
      position: 'top-end',
      //title:'Oops...',
      icon: 'success',
      text: mensaje,
      showConfirmButton: false,
      //showCloseButton: true,
      timer: 1500
    })      
  }

  mensajeAlertaWarning(mensaje:string) {

    Swal.fire({
      position: 'top-end',
      title:'Oops...',
      icon: 'warning',
      text: mensaje,
      showConfirmButton: false,
      //showCloseButton: true,
      timer: 1500
    })      
  }

}
