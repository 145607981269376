
import { Injectable } from '@angular/core';
import { IFiltro } from '../interfaces/filtro';

@Injectable({
  providedIn: 'root'
})
export class FiltroService {

  private filtro:IFiltro={filtroGrabado:false}

  constructor() { }

  setFiltros(filtro:IFiltro) {
    this.filtro=filtro
  }

  getFiltro():IFiltro {

    if (this.filtro.anioRol ==undefined) {
      this.filtro.anioRol=-1
    }

    if (this.filtro.idAnioRol ==undefined) {
      this.filtro.idAnioRol=-1
    }

    return { ...this.filtro }; 
  }

  deleteFiltro(){
   this.filtro={filtroGrabado:false}
  }

  /*grabarLocalStorage(filtroPagina:IFiltro) {
    localStorage.setItem("filtro_usuario",JSON.stringify(filtroPagina))
    //console.log(usuarioSesion)
  }

  obtenerSesionUsuario() {
    const dataCadena=localStorage.getItem("filtro_usuario")
    const usuario=JSON.parse(dataCadena!)
    return usuario
  }

  eliminarSesionUsuario(){
    localStorage.removeItem("filtro_usuario")
  }*/
}
