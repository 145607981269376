import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { SesionUsuarioService } from '../shared/services/sesion-usuario.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent {

    login:string=''
    rol:string=''
    @ViewChild('menubutton') menuButton!: ElementRef;

    constructor(
        public layoutService: LayoutService,
        private usuarioSesionServicio:SesionUsuarioService,
    ) { 

        const usuario=this.usuarioSesionServicio.obtenerSesionUsuario()           
        this.login=usuario.usuarioLogin.toUpperCase()
        this.rol=usuario.nombreRol.toUpperCase() 

    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onProfileButtonClick() {
        this.layoutService.showProfileSidebar();
    }
    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }
    
}