import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { SesionUsuarioService } from './shared/services/sesion-usuario.service';
import { MostrarMensajeService } from './shared/services/mostrar-mensaje.service';


@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(
    private _mensajeriaServicio: MostrarMensajeService,
    private _sesionUsuarioService: SesionUsuarioService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Obtener el token de autenticación desde el almacenamiento local o las cookies

    const token = this._sesionUsuarioService.obtenerToken();

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });

    return next.handle(request).pipe(
      catchError((error: any) => {
        if(!(error.error instanceof ErrorEvent)){
          this.errorsHttpClient(error);
        }
        return throwError(() => error);
      })
    );

  }

  private errorsHttpClient(httpErrorResponse: HttpErrorResponse): void {
    switch (httpErrorResponse.status) {
      case 0:
      case 500:
        this._mensajeriaServicio.mensajeAlertaError('Ocurrio error interno en el servicio.');
        break;
      case 400:
        this._mensajeriaServicio.mensajeAlertaError('Validar petición realizada.');
        break;
      case 401:
        console.log('no auth')
        this._sesionUsuarioService.closeSesion()
        break;
      case 404:
        this._mensajeriaServicio.mensajeAlertaError('No pudimos encontrar tu solicitud, intenta más tarde.');
        break;
    }
  }
}